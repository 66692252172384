#sidebar {
  .menu-segment li:has(a.active) a,
  .menu-segment li:has(a:hover) a {
    font-weight: 600;
    background: #{rgba(map-get($dark-grays, "400"), 0.3)};
  }

  .menu-segment li:has(a.active) a {
    color: $danger;
  }
}
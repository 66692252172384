.component {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;

  &:hover {
    border: 1px solid #FCC;
  }

  &.container-fluid {
    max-width: 100% !important;
  }
}

.component-list {
  position: relative;
  padding-bottom: 15px;
}

.component-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}